<template>
<div class="modal-backdrop">
    <!-- ======== hero-section start ======== -->
    <section id="home" class="hero-section">
      <div class="container">
        <div class="row align-items-center position-relative">
          <div class="col-lg-12 text-center">
            <div class="hero-img wow fadeInUp" data-wow-delay=".5s">
              <h1 v-if="client.hajat == 'wanita'" class="wow fadeInUp" data-wow-delay=".4s" style="font-family: 'Yellowtail', cursive; top: -50px; position: relative;">
                {{client.nama_wanita}} & {{client.nama_pria}}
              </h1>
              <h1 v-if="client.hajat == 'pria'" class="wow fadeInUp" data-wow-delay=".4s" style="font-family: 'Yellowtail', cursive; top: -50px; position: relative;">
                {{client.nama_pria}} & {{client.nama_wanita}}
              </h1>
              <img src="assets/img/hero/hero-img3.png" alt="" style="top: -50px; position: relative;"/>
              <h4 class="wow fadeInUp" data-wow-delay=".6s" style="margin-bottom: 20px; top: -50px; position: relative;">
                {{ config.language === 1 ? "To Mr./Mrs./Sir/Madam" : 'Kepada Bapak/Ibu/Saudara/i' }}
              </h4>
              <h2 class="wow fadeInUp" data-wow-delay=".4s" style="margin-bottom: 20px; font-family: 'Yellowtail', cursive; top: -50px; position: relative;">
                {{guest.nama}}
              </h2>
              <h2 v-if="showName" class="wow fadeInUp" data-wow-delay=".4s" style="margin-bottom: 20px; font-family: 'Yellowtail', cursive; top: -50px; position: relative;">
                {{name}}
              </h2>
              <h5 class="wow fadeInUp" data-wow-delay=".6s" style=" margin-bottom: 20px; top: -50px; position: relative;">
                {{ config.language === 1 ? "Without reducing respect, we invite you to attend our wedding" : 'Tanpa mengurangi rasa hormat, Kami mengundang Anda untuk hadir diacara pernikahan Kami' }}
              </h5>
              <button
                class="main-btn border-btn btn-hover wow fadeInUp"
                style="margin-bottom: 40px; top: -50px; position: relative;"
                data-wow-delay=".6s"
                @click="close"
                >
                {{ config.language === 1 ? "Open Invitation" : 'Buka Undangan' }}
                </button
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======== hero-section end ======== -->
</div>
</template>

<script>
  export default {
    name: 'Modal',
      props: ['client', 'guest', 'config'],
      methods: {
        close() {
          this.$emit('close');
        },
      },
      computed: {
        name() {
            let toParam = this.$route.query.to;
            if (toParam) {
                toParam = decodeURIComponent(toParam);
                if (toParam.includes('-')) {
                return '';
                } else {
                return toParam;
                }
            } else {
                return '';
            }
        },
        showName() {
            let toParam = this.$route.query.to;
            if (toParam) {
                toParam = decodeURIComponent(toParam);
                return !toParam.includes('-');
            } else {
                return false;
            }
        },
    },
  };
</script>